/* eslint-disable no-restricted-syntax, no-await-in-loop */

import { fetchHTTP } from './fetch';

/**
 * Request a IPFS address
 * @param {string} ipfsAdress - The IPFS address to request
 * @return {Promise<Response, null>} The request response or null if request failed
 */
const fetchIpfs = async (ipfsAdress: string): Promise<Response | null> => {
  const matches = ipfsAdress.match(/(?:ipfs:\/\/)([^/\n]*)(?:\/(.*))?/);
  if (matches) {
    const [_, cid, path] = matches;

    const servers = ['nftstorage', 'dweb'];

    for (const server of servers) {
      try {
        // Transform an IPFS address of format ipfs://[address]/[path] to a requestable HTTP address of format https://[address].ipfs.[server].link/[path]
        const url = `https://${cid}.ipfs.${server}.link/${path ?? ''}`;
        const response = await fetchHTTP(url);
        return response;
      } catch (error) {
        console.error(error);
      }
    }

    try {
      // Transform an IPFS address of format ipfs://[address]/[path] to a requestable HTTP address of format https://[address].ipfs.[server].link/[path]
      const url = `https://ipfs.io/ipfs/${cid}/${path ?? ''}`;
      const response = await fetchHTTP(url);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return null;
};

export { fetchIpfs };
