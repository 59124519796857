import { type FC } from 'react';
import { useAccount } from 'wagmi';
import { Route, Routes } from 'react-router-dom';

import Login from 'app/pages/Login';
import Profile from 'app/pages/Profile';
import Gallery from 'app/pages/Gallery';
import useNFTData from 'app/hooks/useNFTData';
import useNFTGallery from 'app/hooks/useNFTGallery';

const App: FC = () => {
  const { status, address } = useAccount();

  const { nftData, fetchStatus } = useNFTData(status, address);
  const { fetchNftGallery, nftGallery } = useNFTGallery();

  if (status !== 'connected') {
    return <Login status={status} key="login" />;
  }

  if (fetchStatus === 'loading') {
    return <Login status="reconnecting" key="login" />;
  }

  if ((nftData === null || nftData.name === '') && ['loaded', 'error'].includes(fetchStatus)) {
    return <Login status="unavailable" />;
  }

  if (fetchStatus === 'loaded' && nftData) {
    return (
      <Routes>
        <Route path="/" element={<Profile nftData={nftData} address={address} />} />
        <Route
          path="/gallery"
          element={
            <Gallery
              user={nftData.cardNumber}
              fetchNftGallery={fetchNftGallery}
              nftGallery={nftGallery}
            />
          }
        />
      </Routes>
    );
  }

  return null;
};

App.displayName = 'App';

export default App;
