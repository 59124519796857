import type { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import Header from 'app/components/Header';
import Footer from 'app/components/Footer';

import style from './style.scss';

const Layout: FC<PropsWithChildren & PropsWithClassName & PropsWithTheme> = ({
  children,
  className,
  theme
}) => (
  <div className={classNames(style.layout, className, style[`theme_${theme}`])}>
    <Header theme={theme} />
    {children}
    <Footer theme={theme} />
  </div>
);

Layout.displayName = 'Layout';

export default Layout;
