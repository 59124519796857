import { useEffect, useState } from 'react';
import { usePublicClient } from 'wagmi';

import { fetchNFTData, getNFTImageURL } from 'app/utils/nftData';

const useNFTData = (
  status: 'disconnected' | 'connected' | 'reconnecting' | 'connecting',
  address: AccountAddress | undefined
) => {
  const [nftData, setNftData] = useState<NFTData | null>(null);
  const [fetchStatus, setFetchStatus] = useState<'idle' | 'loading' | 'loaded' | 'error'>('idle');

  const publicClient = usePublicClient();
  const contractAdress: AccountAddress | undefined = process.env.PANDASCORE_NFT_CONTRACT_ADRESS as
    | AccountAddress
    | undefined;

  useEffect(() => {
    if (publicClient && contractAdress && address && status === 'connected') {
      setFetchStatus('loading');
      fetchNFTData(publicClient, contractAdress, address).then(fetchedNftData => {
        if (fetchedNftData) {
          getNFTImageURL(fetchedNftData).then(imageData => {
            if (imageData && imageData.url) {
              setFetchStatus('loaded');
              setNftData({
                ...fetchedNftData,
                image: imageData.url,
                cardNumber: imageData.cardNumber
              });
            }
          });
        } else {
          setFetchStatus('error');
          setNftData(null);
        }
      });
    } else {
      setFetchStatus('idle');
      setNftData(null);
    }
  }, [publicClient, contractAdress, address, status]);

  return {
    nftData,
    fetchStatus
  };
};

export default useNFTData;
