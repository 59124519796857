import type { FC, PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';

import Icon from 'app/components/Icon';
import closeIcon from 'app/icons/close.svg';

import style from './style.scss';

interface Props extends PropsWithChildren {
  rightContent: ReactElement;
  windowContentClassName?: string;
}

const MultiWindowsContainer: FC<Props> = ({ children, rightContent, windowContentClassName }) => (
  <section className={style.windowsContainer}>
    <div className={style.backgroundWindows} />
    <div className={style.window}>
      <div className={style.windowHeader}>
        <Icon href={closeIcon} className={style.closeIcon} />
        <div className={style.rightContent}>{rightContent}</div>
      </div>
      <div className={classNames(style.windowContent, windowContentClassName)}>{children}</div>
    </div>
  </section>
);

MultiWindowsContainer.displayName = 'MultiWindowsContainer';

export default MultiWindowsContainer;
