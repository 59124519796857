import { useState, type FC } from 'react';
import classNames from 'classnames';

import pixelLockIcon from 'app/icons/pixel_lock.svg';
import pixelUnlockIcon from 'app/icons/pixel_unlock.svg';
import Icon from 'app/components/Icon';

import style from './style.scss';

const Footer: FC<PropsWithTheme> = ({ theme }) => {
  const [lockIcon, setLockIcon] = useState(pixelLockIcon);

  return (
    <footer className={classNames(style.footer, style[`theme_${theme}`])}>
      <div className={style.secret}>
        <span>Made in secret</span>
        <Icon
          href={lockIcon}
          className={style.pixelLockicon}
          onMouseEnter={() => setLockIcon(pixelUnlockIcon)}
          onMouseLeave={() => setLockIcon(pixelLockIcon)}
        />
        <span>with a secret team</span>
      </div>
      <div>pandascore</div>
    </footer>
  );
};

Footer.displayName = 'Footer';

export default Footer;
