// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b9db9-layout{min-height:100%;display:grid;grid-template-rows:min-content minmax(min-content, 1fr) min-content}.a99c2-theme_light{color:#141414;background-color:#fff}.d42ff-theme_dark{background-image:url("./images/background_gradient.png");color:#cecece;background-color:#141414;background-repeat:no-repeat;background-position:top;background-attachment:fixed}`, "",{"version":3,"sources":["webpack://./app/components/Layout/style.scss","webpack://./app/styles/variables.scss"],"names":[],"mappings":"AAEA,cACE,eAAA,CACA,YAAA,CACA,mEAAA,CAGF,mBACE,aCJkB,CDKlB,qBCJwB,CDO1B,kBACE,wDAAA,CACA,aCfiB,CDgBjB,wBCduB,CDevB,2BAAA,CACA,uBAAA,CACA,2BAAA","sourcesContent":["@import \"variables\";\n\n.layout {\n  min-height: 100%;\n  display: grid;\n  grid-template-rows: min-content minmax(min-content, 1fr) min-content;\n}\n\n.theme_light {\n  color: $light__text_color;\n  background-color: $light__background_color;\n}\n\n.theme_dark {\n  background-image: url(\"./images/background_gradient.png\");\n  color: $dark__text_color;\n  background-color: $dark__background_color;\n  background-repeat: no-repeat;\n  background-position: top;\n  background-attachment: fixed;\n}\n","$dark__text_color: #cecece;\n$dark__secondary_text_color: rgba(245, 245, 247, 0.5);\n$dark__background_color: #141414;\n$dark__border_color: rgba(245, 245, 247, 0.2);\n\n$light__text_color: #141414;\n$light__background_color: #fff;\n$light__border_color: #141414;\n\n$header_height: 76px;\n\n// $card_width: 320px;\n$card_width: 440px;\n$card_ratio: 1.375; // DO NOT CHANGE\n$card_height: $card_width * $card_ratio;\n\n$perspective: 800px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `b9db9-layout`,
	"theme_light": `a99c2-theme_light`,
	"theme_dark": `d42ff-theme_dark`
};
export default ___CSS_LOADER_EXPORT___;
