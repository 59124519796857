const fetchHTTP: typeof fetch = async (...args) => {
  try {
    const response = await fetch(...args);
    if (response.ok && response.status < 400) {
      return response;
    }
    throw new Error(response.status.toFixed());
  } catch (error) {
    throw new Error();
  }
};

export { fetchHTTP };
