const round = (value: number, decimals = 2): number =>
  Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);

const random = (min = 0, max = 1, decimals = 2): number =>
  round(Math.random() * (max - min) + min, decimals);

const adjust = (value: number, fromMin: number, fromMax: number, toMin: number, toMax: number) =>
  round(toMin + ((toMax - toMin) * (value - fromMin)) / (fromMax - fromMin));

const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min) + min);

export { round, random, adjust, getRandomInt };
