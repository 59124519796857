import { useState, type FC, useEffect, type MouseEventHandler, type CSSProperties } from 'react';

import Layout from 'app/components/Layout';
import Icon from 'app/components/Icon';
import fistBumpIcon from 'app/icons/fist_bump.svg';
import icon404 from 'app/icons/404.svg';
import { getRandomInt, round } from 'app/utils/math';
import faceA from 'app/icons/face_a.png';
import faceB from 'app/icons/face_b.png';
import faceC from 'app/icons/face_c.png';

import style from './style.scss';
import jokes from './jokes.json';

interface CursorValue {
  x: number;
  y: number;
}

const Unknown: FC = () => {
  const [jokeIndex, setJokeIndex] = useState(getRandomInt(0, jokes.length));

  const nextJoke = () => {
    setJokeIndex(index => (index + 1 === jokes.length ? 0 : index + 1));
  };

  const animationDuration = 20;

  useEffect(() => {
    setInterval(nextJoke, animationDuration * 1000);
  }, []);

  const [rotate, setRotate] = useState<CursorValue>({ x: 0, y: 0 });

  let leaveTimeout: NodeJS.Timeout;

  const onMouseLeave = () => {
    leaveTimeout = setTimeout(() => {
      setRotate({ x: 0, y: 0 });
    }, 500);
  };

  const onMouseMove: MouseEventHandler<HTMLDivElement> = event => {
    clearTimeout(leaveTimeout);
    const $el = event.target as HTMLDivElement;
    const rect = $el.getBoundingClientRect(); // get element's current size/position
    const absolute = {
      x: event.clientX - rect.left, // get mouse position from left
      y: event.clientY - rect.top // get mouse position from right
    };
    const percent = {
      x: (100 * absolute.x) / rect.width,
      y: (100 * absolute.y) / rect.height
    };
    const center = {
      x: percent.x - 50,
      y: percent.y - 50
    };
    setRotate({
      x: round(-(center.x / 2)),
      y: round(center.y / 2)
    });
  };

  const styles: CSSProperties = {
    ['--rotate-x' as any]: `${rotate.y - 35}deg`,
    ['--rotate-y' as any]: `${rotate.x + 31}deg`
  };

  return (
    <Layout theme="light">
      <main className={style.container}>
        <section className={style.section_hero}>
          {/* <img src={nftData.image} alt={nftData.name} className={style.crate} /> */}
          <div
            className={style.crateContainer}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            <div className={style.crate} style={styles}>
              <img src={faceA} alt="" className={style.faceA} />
              <img src={faceB} alt="" className={style.faceB} />
              <img src={faceC} alt="" className={style.faceC} />
            </div>
          </div>

          <div className={style.infoContainer}>
            <h1>Panda not (yet) found</h1>
            <Icon href={icon404} className={style.icon404} />
          </div>
        </section>
        <section className={style.section_jokes}>
          <div
            className={style.jokesContainer}
            style={{
              ['--animation-duration' as any]: `${animationDuration}s`
            }}
          >
            <span>
              404 - Panda Not found, but don&apos;t worry dear Panda. Your time is coming, the team
              is working on it.
            </span>
            <span>
              <Icon href={fistBumpIcon} className={style.fistBumpIcon} />
            </span>
            <span>In the meantime, here is one for the road:</span>
            <span className={style.joke}>{jokes[jokeIndex]}</span>
          </div>
        </section>
      </main>
    </Layout>
  );
};

Unknown.displayName = 'Unknown';

export default Unknown;
