import type { PublicClient } from 'wagmi';

import abi from 'app/abi';

import { fetchIpfs } from './ipfs';

interface NFTAttributeData {
  arrival?: string;
  guild?: string;
  role?: string;
}

const fetchNFTData = async (
  publicClient: PublicClient,
  contractAdress: AccountAddress,
  account: AccountAddress
): Promise<NFTData | null> => {
  try {
    const locationIndex = await publicClient.readContract({
      address: contractAdress,
      abi,
      functionName: 'tokenOfOwnerByIndex',
      args: [account, BigInt(0)]
    });
    const tokenURI = await publicClient.readContract({
      address: contractAdress,
      abi,
      functionName: 'tokenURI',
      args: [locationIndex]
    });
    const response = await fetchIpfs(tokenURI);
    if (response) {
      const data = (await response.json()) as NFTData;
      return data;
    }
    throw new Error();
  } catch {
    return null;
  }
};

const getNFTImageURL = async (
  data: NFTData
): Promise<{ url: string; cardNumber: string | null } | null> => {
  const image = await fetchIpfs(data.image);
  const cardNumber = new URL(image?.url || '').pathname.match(/\d+/)?.[0] ?? null;
  if (image) {
    return {
      url: image.url,
      cardNumber
    };
  }
  return null;
};

const getNFTColors = (data: NFTData): NFTColors => {
  if (!data?.properties) {
    return {
      main_color: '',
      secondary_color: '',
      font_color: ''
    };
  }

  const { font_color, main_color, secondary_color } = data.properties.card.properties;

  return {
    main_color: main_color.value,
    secondary_color: secondary_color.value,
    font_color: font_color.value
  };
};

const getAttributesData = (data: NFTData): NFTAttributeData => {
  const attributesData: NFTAttributeData = {};

  const arrival = data.attributes.find(attr => attr.trait_type === 'Arrival');
  if (arrival) {
    attributesData.arrival = new Date(parseInt(`${arrival.value}`) * 1000).toDateString();
  }

  const guild = data.attributes.find(attr => attr.trait_type === 'Guild');
  if (guild) {
    attributesData.guild = guild.value as string;
  }

  const role = data.attributes.find(attr => attr.trait_type === 'Role');
  if (role) {
    attributesData.role = role.value as string;
  }

  return attributesData;
};

const fetchAllNFTs = async (publicClient: PublicClient, contractAdress: AccountAddress) => {
  try {
    const numberOfPandas = await publicClient.readContract({
      address: contractAdress,
      abi,
      functionName: 'totalSupply'
    });
    const promises = new Array(parseInt(numberOfPandas.toString())).fill(null).map((_, index) =>
      publicClient
        .readContract({
          address: contractAdress,
          abi,
          functionName: 'tokenURI',
          args: [index as unknown as bigint]
        })
        .then(tokenURI => fetchIpfs(tokenURI))
        .then(response => response?.json())
        .then(nftData => nftData as NFTData)
        .then(async nftData => {
          if (nftData.name !== 'A crate') {
            const imageData = await getNFTImageURL(nftData);
            if (imageData) {
              return {
                ...nftData,
                image: imageData.url,
                cardNumber: imageData.cardNumber
              };
            }
          }
          return null;
        })
        .catch(() => null)
    );
    const allPromisesResult = (await Promise.allSettled(promises))
      .map(promiseResult => {
        if (promiseResult.status === 'fulfilled' && promiseResult.value !== null) {
          return promiseResult.value;
        }
        return null;
      })
      .filter(Boolean) as NFTData[];
    return allPromisesResult;
  } catch {
    return [];
  }
};

export { fetchNFTData, getNFTImageURL, getNFTColors, getAttributesData, fetchAllNFTs };
