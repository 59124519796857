import { createRoot } from 'react-dom/client';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { BrowserRouter } from 'react-router-dom';
import { mainnet } from 'wagmi/chains';

import App from 'app/App';

import './style.scss';

const container = document.getElementById('root');
const root = createRoot(container!);

const chains = [mainnet];
const projectId = process.env.WALLETCONNECT_PROJECT_ID ?? '';

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({ projectId, chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Panda NFT Gallery'
      }
    }),
    new MetaMaskConnector({ chains })
  ],
  publicClient
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

root.render(
  <BrowserRouter>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
    <Web3Modal
      projectId={projectId}
      enableAccountView={false}
      walletImages={{
        coinbaseWallet:
          'https://explorer-api.walletconnect.com/w3m/v1/getWalletImage/a5ebc364-8f91-4200-fcc6-be81310a0000?projectId=d39dc00298dfc83b1c9501d62d27c68b',
        metaMask:
          'https://explorer-api.walletconnect.com/w3m/v1/getWalletImage/5195e9db-94d8-4579-6f11-ef553be95100?projectId=d39dc00298dfc83b1c9501d62d27c68b'
      }}
      explorerExcludedWalletIds={[
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
      ]}
      ethereumClient={ethereumClient}
      themeMode="light"
      themeVariables={{
        '--w3m-background-color': '#fff',
        '--w3m-background-border-radius': '0px',
        '--w3m-container-border-radius': '0px',
        '--w3m-button-border-radius': '0px',
        '--w3m-secondary-button-border-radius': '0px',
        '--w3m-icon-button-border-radius': '0px',
        '--w3m-accent-color': 'transparent',
        '--w3m-accent-fill-color': '#141414',
        '--w3m-overlay-background-color': 'rgba(0, 0, 0, 0.8'
      }}
    />
  </BrowserRouter>
);
