import { type FC, useEffect, type PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import Layout from 'app/components/Layout';
import { getNFTColors } from 'app/utils/nftData';
import Card from 'app/components/Card';

import style from './style.scss';

interface Props {
  user?: string | null;
  fetchNftGallery: () => void;
  nftGallery: NFTData[];
}

const Wrapper: FC<PropsWithChildren<{ link: boolean }>> = ({ children, link }) => {
  if (link) {
    return <Link to="/">{children}</Link>;
  }
  return <>{children}</>;
};

const Gallery: FC<Props> = ({ user, fetchNftGallery, nftGallery }) => {
  useEffect(() => {
    fetchNftGallery();
  }, []);

  return (
    <Layout theme="dark">
      <main className={style.gallery}>
        {nftGallery.map(nft => {
          if (nft.image) {
            return (
              <Wrapper
                key={nft.cardNumber}
                link={!!nft.cardNumber && !!user && nft.cardNumber === user}
              >
                <div className={style.cardContainer}>
                  <Card
                    img={nft.image}
                    name={nft.name}
                    cardNumber={nft.cardNumber}
                    colors={getNFTColors(nft)}
                    isFlipped={false}
                    mode="simple"
                  />
                </div>
              </Wrapper>
            );
          }
          return null;
        })}
      </main>
    </Layout>
  );
};

Gallery.displayName = 'Gallery';

export default Gallery;
