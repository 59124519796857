import type { FC, CSSProperties, DetailedHTMLProps, HTMLAttributes } from 'react';

interface Props extends DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement> {
  href: SVG;
  className?: string;
  style?: CSSProperties;
  breakAspectRatio?: boolean;
}

const Icon: FC<Props> = ({ href, className, style, breakAspectRatio = false, ...props }) => (
  <svg
    viewBox={`${href.viewBox}`}
    className={className}
    style={style}
    {...(breakAspectRatio ? { preserveAspectRatio: 'none' } : {})}
    {...props}
  >
    <use xlinkHref={`#${href.id}`} />
  </svg>
);

Icon.displayName = 'Icon';

export default Icon;
