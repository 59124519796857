import type { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import style from './style.scss';

const Header: FC<PropsWithTheme> = ({ theme }) => (
  <header className={classNames(style.header, style[`theme_${theme}`])}>
    <Link to="/">
      <h1>Pandas NFT Gallery</h1>
    </Link>
    <Link to="/gallery">1 : 1 collection</Link>
  </header>
);

Header.displayName = 'Header';

export default Header;
