import type { FC } from 'react';

import Panda from './Panda';
import Unknown from './Unknown';

interface Props {
  nftData: NFTData;
  address: AccountAddress | undefined;
}

const Profile: FC<Props> = ({ nftData, address }) => {
  if (nftData.name === 'A crate') {
    return <Unknown />;
  }

  return <Panda nftData={nftData} account={address} />;
};

Profile.displayName = 'Profile';

export default Profile;
