import { useState } from 'react';
import { usePublicClient } from 'wagmi';

import { fetchAllNFTs } from 'app/utils/nftData';

const useNFTGallery = () => {
  const [nftGallery, setNftGallery] = useState<NFTData[]>([]);

  const publicClient = usePublicClient();
  const contractAdress: AccountAddress | undefined = process.env.PANDASCORE_NFT_CONTRACT_ADRESS as
    | AccountAddress
    | undefined;

  const fetchNftGallery = () => {
    if (nftGallery.length === 0 && contractAdress) {
      fetchAllNFTs(publicClient, contractAdress).then(data => {
        setNftGallery(data);
      });
    }
  };

  return {
    fetchNftGallery,
    nftGallery
  };
};

export default useNFTGallery;
