import {
  useState,
  type FC,
  type MouseEventHandler,
  type TouchEventHandler,
  type CSSProperties,
  useRef,
  useLayoutEffect
} from 'react';
import tinycolor from 'tinycolor2';

import Icon from 'app/components/Icon';
import background from 'icons/card_border_effect.svg';
import { adjust, round } from 'app/utils/math';
import cardBack from 'app/icons/card_back.png';
import glitter from 'app/icons/glitter.png';
import grain from 'app/icons/grain.png';

import style from './style.scss';

interface Props {
  img: string;
  name: string;
  isFlipped: boolean;
  colors: NFTColors;
  cardNumber?: string | null;
  mode: 'simple' | 'full';
}

interface CursorValue {
  x: number;
  y: number;
}

const Card: FC<Props> = ({ img, name, isFlipped, colors, cardNumber, mode }) => {
  const [rotate, setRotate] = useState<CursorValue>({ x: 0, y: 0 });
  const [position, setPosition] = useState<CursorValue>({ x: 50, y: 50 });
  const [cardSize, setCardSize] = useState(1);

  const cardRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  let leaveTimeout: NodeJS.Timeout;

  const handleSize = () => {
    const cardWidth = cardRef.current?.getBoundingClientRect().width;
    if (cardWidth) {
      setCardSize(round(Math.min(Math.floor(cardWidth), 440) / 440, 2));
    }
  };

  useLayoutEffect(() => {
    if (cardRef.current) {
      handleSize();

      window.addEventListener('resize', handleSize);

      return () => window.removeEventListener('resize', handleSize);
    }
  }, [cardRef]);

  const onMouseMove: MouseEventHandler<HTMLDivElement> = event => {
    clearTimeout(leaveTimeout);
    const $el = event.target as HTMLDivElement;
    const rect = $el.getBoundingClientRect(); // get element's current size/position
    const absolute = {
      x: event.clientX - rect.left, // get mouse position from left
      y: event.clientY - rect.top // get mouse position from right
    };
    const percent = {
      x: (100 * absolute.x) / rect.width,
      y: (100 * absolute.y) / rect.height
    };
    const center = {
      x: percent.x - 50,
      y: percent.y - 50
    };
    setRotate({
      x: round(-(center.x / 2)),
      y: round(center.y / 2)
    });
    setPosition({
      x: round(percent.x),
      y: round(percent.y)
    });
  };

  const onTouchMove: TouchEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    event.stopPropagation();
    clearTimeout(leaveTimeout);
    const $el = event.target as HTMLDivElement;
    const rect = $el.getBoundingClientRect(); // get element's current size/position
    const absolute = {
      x: event.touches[0].clientX - rect.left, // get mouse position from left
      y: event.touches[0].clientY - rect.top // get mouse position from right
    };
    const percent = {
      x: (100 * absolute.x) / rect.width,
      y: (100 * absolute.y) / rect.height
    };
    const center = {
      x: percent.x - 50,
      y: percent.y - 50
    };
    setRotate({
      x: round(-(center.x / 2)),
      y: round(center.y / 2)
    });
    setPosition({
      x: round(percent.x),
      y: round(percent.y)
    });
  };

  const onMouseLeave = () => {
    leaveTimeout = setTimeout(() => {
      setRotate({ x: 0, y: 0 });
      setPosition({ x: 50, y: 50 });
    }, 500);
  };

  const styles: CSSProperties = {
    ['--rotate-x' as any]: `${rotate.x + (isFlipped ? 180 : 0)}deg`,
    ['--rotate-y' as any]: `${rotate.y * (isFlipped ? -1 : 1)}deg`,
    ['--pointer-x' as any]: `${position.x}%`,
    ['--pointer-y' as any]: `${position.y}%`,
    ['--pointer-x-coeff' as any]: position.x / 100,
    ['--pointer-y-coeff' as any]: position.y / 100,
    ['--background-x' as any]: `${adjust(position.x, 0, 100, 33, 67)}%`,
    ['--background-y' as any]: `${adjust(position.y, 0, 100, 33, 67)}%`,
    // ['--background-x' as any]: `${(position.x - 50) / 7 + (position.y - 50) / 4}%`,
    // ['--background-y' as any]: `${(position.y - 50) / 7}%`,
    ['--main-color' as any]: colors.main_color,
    ['--secondary-color-darken' as any]: tinycolor(colors.secondary_color).darken(20),
    ['--glitter' as any]: `url("${glitter}")`,
    ['--glittesize' as any]: '25%',
    ['--grain' as any]: `url("${grain}")`,
    ['--card-size' as any]: cardSize
  };

  const fullEffect = mode === 'full';

  return (
    <div
      className={style.card}
      style={styles}
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
      onMouseLeave={onMouseLeave}
      onTouchCancel={onMouseLeave}
      ref={cardRef}
    >
      <div className={style.cardColor} style={{ backgroundColor: colors.secondary_color }}>
        <div className={style.cardBorderEffect}>
          <Icon
            href={background}
            style={{
              height: '100%',
              color: colors.font_color,
              transitionDelay: isFlipped ? '0s' : '0.05s',
              opacity: isFlipped ? 0 : 1
            }}
          />
        </div>
      </div>
      {fullEffect && (
        <img
          src={cardBack}
          className={style.card_back}
          alt="card back"
          style={{ zIndex: isFlipped ? 1 : 0 }}
        />
      )}
      <div className={style.card_front}>
        <div className={style.card_nft_container}>
          <img
            src={img}
            className={style.card_nft}
            alt={`${name}'s NFT card`}
            ref={imgRef}
            crossOrigin="anonymous"
          />
        </div>
      </div>
      <div className={style.card_shine} />

      <div
        className={style.card_name}
        style={{
          background: colors.secondary_color,
          color: colors.font_color
        }}
      >
        {name}
        {cardNumber && <div className={style.card_number}>#{cardNumber}</div>}
      </div>
      {/* <div className={style.card_glare} /> */}
    </div>
  );
};

Card.displayName = 'Card';

export default Card;
