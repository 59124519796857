import { type FC, useEffect, useRef, useCallback } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import { disconnect } from '@wagmi/core';

import MultiWindowsContainer from 'app/components/MultiWindowsContainer';
import sadIcon from 'app/icons/sad.svg';
import Icon from 'app/components/Icon';
import handCursorIcon from 'app/icons/cursor_hand.svg';
import hourglassIcon from 'app/icons/hourglass.svg';
import loader from 'app/icons/loader.svg';
import Layout from 'app/components/Layout';

import style from './style.scss';

interface Props {
  status?: 'disconnected' | 'reconnecting' | 'connecting' | 'unavailable';
}

const Login: FC<Props> = ({ status }) => {
  const { open, isOpen } = useWeb3Modal();

  const reconnectingTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleReconnectionIssue = useCallback(() => {
    disconnect().then(() => {
      open();
    });
  }, [open]);

  useEffect(() => {
    if (status === 'reconnecting') {
      reconnectingTimeout.current = setTimeout(handleReconnectionIssue, 5000);
    }

    return () => {
      if (reconnectingTimeout.current) {
        clearTimeout(reconnectingTimeout.current);
      }
    };
  }, [status, open]);

  return (
    <Layout theme="light">
      <main className={style.container}>
        <MultiWindowsContainer
          rightContent={
            status === 'unavailable' ? (
              <>
                <Icon href={hourglassIcon} className={style.hourglassIcon} />
                <span className={style.rightHeaderContentText}>Oops</span>
              </>
            ) : (
              <>
                <Icon href={handCursorIcon} className={style.handCursorIcon} />
                <span className={style.rightHeaderContentText}>Welcome</span>
              </>
            )
          }
        >
          {status === 'unavailable' && (
            <>
              <h1 className={style.title}>There is an error</h1>
              <Icon href={sadIcon} className={style.sadIcon} />
            </>
          )}
          {(status === 'disconnected' || (status === 'connecting' && !isOpen)) && (
            <>
              <h1 className={style.title}>Panda authentication required!</h1>
              <button className={style.connectButton} onClick={() => open()} type="button">
                Let me in
              </button>
            </>
          )}
          {((status === 'connecting' && isOpen) || status === 'reconnecting') && (
            <>
              <h1 className={style.title}>Discovery in progress...</h1>
              <div className={style.loader}>
                <div className={style.loaderContainer}>
                  <Icon href={loader} className={style.svgLoader} />
                </div>
              </div>
            </>
          )}
        </MultiWindowsContainer>
      </main>
    </Layout>
  );
};

Login.displayName = 'Login';

export default Login;
