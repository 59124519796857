import { type FC, useState } from 'react';

import Card from 'app/components/Card';
import Layout from 'app/components/Layout';
import { getAttributesData, getNFTColors } from 'app/utils/nftData';
import Icon from 'app/components/Icon';
import flipIcon from 'app/icons/flip.svg';

import style from './style.scss';

interface Props {
  nftData: NFTData;
  account: `0x${string}` | undefined;
}

const Panda: FC<Props> = ({ nftData, account }) => {
  const attributesData = getAttributesData(nftData);

  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <Layout theme="dark">
      <main className={style.profile}>
        <section className={style.section_card}>
          <div className={style.cardContainer}>
            {/* <div
              className={style.cardRotator}
              style={{
                transform: `rotateY(${isFlipped ? 540 : 0}deg)`
              }}
            > */}
            {nftData.image && (
              <Card
                img={nftData.image}
                name={nftData.name}
                cardNumber={nftData.cardNumber}
                colors={getNFTColors(nftData)}
                isFlipped={isFlipped}
                mode="full"
              />
            )}
            {/* </div> */}
          </div>
          <button
            onClick={() => setIsFlipped(flipped => !flipped)}
            className={style.flipButton}
            type="button"
          >
            <Icon href={flipIcon} className={style.flipIcon} />
            <div>
              <span>MAKE ME SPIN</span>
              <span>come on, try it</span>
            </div>
          </button>
        </section>
        <section className={style.section_info}>
          <div className={style.infoContainer}>
            <h1>{nftData.name}</h1>
            <h2>{account}</h2>
            <ul className={style.infoTable}>
              {/* <li>
                <span>Level</span>
                <span>Silver</span>
              </li> */}
              {attributesData.arrival && (
                <li>
                  <span>Arrival</span>
                  <span>{attributesData.arrival}</span>
                </li>
              )}
              {attributesData.role && (
                <li>
                  <span>Role</span>
                  <span>{attributesData.role}</span>
                </li>
              )}
              {attributesData.guild && (
                <li>
                  <span>Guild</span>
                  <span>{attributesData.guild}</span>
                </li>
              )}
            </ul>
          </div>
        </section>

        {/* <section className={style.section_more}>
        <div>
          <h3>Discover the full collection</h3>
          <Link label="Over there" href="/" />
        </div>
        <div>
          <h3>
            Want to earn more <Redacted key="badges">Badges?</Redacted>
          </h3>
          <Link label="That way" href="/" />
        </div>
      </section> */}
      </main>
    </Layout>
  );
};

Panda.displayName = 'Panda';

export default Panda;
